@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira:wght@400;500;700;900&display=swap');
@import './styles/vars.scss';
@import './styles/global';
@import './build/ui/styles.scss';

body {
  margin: 0;
  font-family: 'Saira Condensed', sans-serif;
  color: white;
}

html,
body {
  overscroll-behavior: none;
  overscroll-behavior-y: none;
}

.saira {
  font-family: 'Saira Condensed', sans-serif;
}

#root {
  max-width: 100vw;
  overflow: hidden;
}

.tyc h2 {
  @apply text-xl;
  @apply mb-4;
  @apply font-bold;
}

.tyc h2,
.tyc ol {
  font-family: 'DINPro';
  @apply mb-4;
}

.tyc li {
  text-align: justify;
  @apply mb-2;
}

.dinPro {
  font-family: 'DINPro';
}

.saira {
  font-family: 'Saira', sans-serif;
}

@media screen and (min-height: 1080px) {
  .large-screen-padding {
    @apply my-auto;
    min-height: 960px;
  }
}

.tecate-premios {
  max-height: 256px;
}

@media screen and (max-height: 640px) {
  .tecate-premios {
    max-height: 198px;
  }
}

@media screen and (max-height: 568px) {
  .tecate-premios {
    max-height: 172px;
  }
}

.min-h-view {
  // min-height: 100vh;
  /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);
}

.h-view {
  // min-height: 100vh;
  /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.slick-next,
.slick-prev {
  top: 38%;
}

@media only screen and (max-width: 1279px) {
  .slick-next,
  .slick-prev {
    top: 35%;
  }
}

@media only screen and (max-width: 1023px) {
  .slick-next,
  .slick-prev {
    top: 20%;
  }
}

.animation-8 {
  animation-duration: 8s;
}

.crown-bg {
  border: 4px solid white;
  border-radius: 50%;
}

input:focus {
  outline: none;
}

input[name='code']:focus {
  outline: none;
  color: black;
}

input:focus::placeholder {
  opacity: 0;
  color: #fff;
}

input:focus:-ms-input-placeholder {
  opacity: 0;
  color: #fff;
}

input:focus::-ms-input-placeholder {
  opacity: 0;
  color: #fff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input {
  -moz-appearance: textfield;
}

.bk-red button[disabled] {
  border-color: #fff !important;
  color: #fff !important;
}

.slick-next {
  right: 15px;
}

.slick-prev {
  left: 15px;
}

.slick-next:before,
.slick-prev:before {
  color: #000 !important;
}

.slick-arrow {
  width: 2.5rem !important;
  height: 2.5rem !important;
  z-index: 800;

  @media screen and (min-width: 1024px) {
    transform: translateY(-5.7rem);
  }

  @media screen and (min-width: 1280px) {
    transform: translateY(-6.5rem);
  }
}

.slick-dots {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.slick-dots li {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.slick-dots .slick-active div {
  opacity: 100 !important;
}

.content-input {
  padding: 5px 0 5px 40px;
  display: block;
  cursor: pointer;

  input {
    visibility: hidden;
    position: absolute;
    right: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
  }

  input + i {
    background: none;
    border: 2px solid white;
    position: absolute;
    left: 0;
    top: 0;
  }

  input[type='radio'] + i {
    height: 30px;
    width: 30px;
    border-radius: 100%;
    left: 0;
  }

  input[type='radio'] + i:before {
    content: '';
    display: block;
    height: 18px;
    width: 18px;
    border-radius: 100%;
    position: absolute;
    z-index: 1;
    top: 4px;
    left: 4px;
    background: white;
    transition: all 0.25s ease;
    transform: scale(0);
    opacity: 0;
  }

  input[type='radio']:checked + i:before {
    transform: scale(1);
    opacity: 1;
  }

  :hover input[type='radio']:not(:checked) + i {
    background: white;
  }
}

.list-winners {
  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    background: rgba(225, 37, 26, 1);
    /* color of the tracking area */
  }

  ::-webkit-scrollbar-thumb {
    background-color: blue;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 3px solid orange;
    /* creates padding around scroll thumb */
  }
}

.list-winners::-webkit-scrollbar {
  width: 0.6rem;
}

.list-winners::-webkit-scrollbar-track {
  background: rgba(225, 37, 26, 1);
  /* color of the tracking area */
}

.list-winners::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 3px;
  /* roundness of the scroll thumb */
  border: 3px solid rgba(225, 37, 26, 1);
  /* creates padding around scroll thumb */
}

// ANIMACION PULSE

.anim-pulse {
  // box-shadow: 0 0 0 0 rgba(255, 0, 0, 1);
  transform: scale(1);
  animation: pulse-bounce 2s infinite;
  border-radius: 100%;
}

@keyframes pulse-bounce {
  0% {
    transform: scale(0.95);
    // box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    // box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    // box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

.button-nav:hover {
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
}

@keyframes scaleIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.scale-in {
  animation: scaleIn 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes scaleOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.scale-out {
  animation: scaleOut 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.flip-card {
  background-color: transparent;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.flip-card-active {
  transform: rotateY(180deg);
}

.app-loading {
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 140px;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  position: fixed;
  z-index: 10005 !important;
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-width: 1.5px;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }

    100% {
      stroke-dasharray: 89, 200;
      stroke-width: 0.5px;
      stroke-dashoffset: -124px;
    }
  }

  .spinner {
    height: 200px;
    width: 200px;
    animation: rotate 2s linear infinite;
    transform-origin: center center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .spinner .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke-width: 1.2px;
    stroke: white;
  }
}

.grid-container-album {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 16px;
  padding: 0 24px;
}

.google-wallet {
  height: 49px;
  border: 1px solid #747775;
  background-color: #1f1f1f;
  border-radius: 9999px;
}

.box-shadow-card-qr {
  border-radius: 8px;
  box-shadow: 0px 0px 9.257px 0px rgba(0, 0, 0, 0.35);
}

/* center the "X" of the cookie popup */
#ot-sdk-btn-floating .ot-floating-button__back svg {
  margin: 0 auto;
}

.title {
  &.big {
  }

  &.medium {
  }

  &.small {
  }
}

.subtitle {
  &.big {
  }

  &.medium {
  }

  &.small {
  }
}

.description {
  &.big {
  }

  &.medium {
  }

  &.small {
  }
}

.text {
  &.big {
  }

  &.medium {
  }

  &.small {
  }
}

.button {
  &-generic {
  }

  &.underline {
    &.big {
    }

    &.medium {
    }

    &.small {
    }
  }
}

.canvas-image {
  aspect-ratio: 0.56 / 1;
}

.referrals {
  &__modal {
    &__show-rules {
    }
    &__title {
    }
    &__content {
      &__img {
      }
    }
  }
  &__rules {
    &__title {
    }
    &__list {
      p {
      }
    }
  }

  &__header {
    &__arrow {
    }
    &__title {
    }
    &__download-btn {
    }
  }

  &__progress-bar {
    &__info {
      &__referrals {
      }
      &__points {
      }
    }

    &__track {
      &__fill {
      }
    }
  }

  &__code {
    &__title {
    }

    &__qr {
    }

    &__share {
      &__text {
      }
    }

    &__hex-digit {
      p {
      }
    }

    &__button {
      &__copy {
      }
      &__share {
      }
    }

    &__dynamic {
      &__title {
      }
      &__icon {
      }

      &__content {
        &__empty {
        }

        &__active {
        }

        &__list-item {
        }

        &__prize {
          &__title {
          }
          &__img {
          }
        }
      }
    }
  }
}
