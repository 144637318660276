@use '../../styles/theme/components/main';
@use '../../styles/theme/components/backgroundImage';
@use '../../styles/theme/components/texts';
@use '../../styles/theme/components/button';
@import '../../styles/vars.scss';

@font-face {
  font-family: 'Balinese Culture';
  src: url('https://promohubstorage.blob.core.windows.net/heineken-waiter/assets/fonts/Balinese-Culture.woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Frank';
  src: url('https://promohubstorage.blob.core.windows.net/heineken-waiter/assets/fonts/Frank-Regular.woff2');
  font-weight: 400;
  font-style: normal;
}

.frank {
  font-family: Frank;
}

@mixin buttonGeneric {
  @include button.button(
    $radius: 50px,
    $family: 'Balinese Culture',
    $letter-spacing: normal,
    $font-size: 18px,
    $font-weight: 400,
    $padding: 1rem 8px,
    $background: black,
    $color: white
  );
}

.heinekenWaiter {
  .sign-up {
    &__welcome {
      background-image: none;
      background-color: white;

      &__description {
        color: #000;
        text-align: center;
        font-family: Frank;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &__email {
          color: #b54540;
        }
      }

      &__resend {
        color: #b54540;
        font-family: Frank;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &__otp {
        background-color: #fbbc4d;

        &__subtitle {
          color: black;
          text-align: center;
          font-family: Frank;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        &__description {
          text-align: center;
          font-family: Frank;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: black;
        }

        &__email {
          color: #b54540;
        }
      }
    }

    &__done {
      background-color: white;
      background-image: none;

      &__title {
      }

      &__description {
      }
    }

    &__scan-qr {
      &__title {
        color: #fff;
        text-align: center;
        font-family: Frank;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        text-transform: uppercase;
      }

      .square {
        width: 100%;
        height: 100%;
        background: transparent;
        position: absolute;
        top: 0;
        left: 0;
      }

      .scan {
        width: 100%;
        height: 10px;
        background: linear-gradient(transparent, #fbbc4d, transparent);
        animation: scanning 1.5s linear alternate infinite;
      }

      @keyframes scanning {
        0% {
          transform: translatey(0px);
        }
        100% {
          transform: translatey(325px);
        }
      }
    }
  }

  .scanning-success-animation {
    background-color: rgba(0, 255, 0, 0.2); /* Verde translúcido */
  }

  .login {
    // -- Mail Sent Password --
    &__mail-sent-password {
      background-color: white;
      background-image: none;

      &__title {
        font-family: Balinese Culture;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
      }

      &__recovery {
        p {
        }
      }
    }

    &__help {
      color: #000;
      text-align: center;
      font-family: Frank;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      margin-bottom: 10px;
      text-decoration: underline;
    }

    &__exchange {
      background-color: #b54540;
      background-image: none;

      .title {
        color: #fff;
        text-align: center;
        font-family: 'Balinese Culture';
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
      }

      .description {
        color: #fff;
        text-align: center;
        font-family: Frank;
        font-size: 14px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;

        span {
          font-weight: 400;
        }

        p:nth-of-type(2) {
          line-height: normal;
        }
      }

      .exchange_points_crown {
        p {
          color: #000;
          text-align: center;
          font-family: 'Balinese Culture';
          font-size: 80px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
        }

        span {
          display: none;
        }
      }
    }
  }

  .trivias {
    &__popup {
      &__intro {
        .popup__intro {
          &-information {
            color: #193973;
            font-family: Frank;

            .text {
              text-transform: uppercase;
              font-size: 10px;
            }

            .description {
              border-color: #d09b34;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  // -- Redeem --
  .redeem {
    background-image: none;

    input {
      text-align: left;
      padding: 27px 20px;
      border-radius: 25px;

      &::placeholder {
        text-align: left;
      }
    }

    button {
      border-radius: 25px;
    }
  }

  .survey {
    background-image: none;

    @include buttonGeneric();

    .input-survey-textarea {
      text-transform: none;
      border-radius: 15px;
      background-color: white;
      font-weight: 400;
      font-size: 16px;
      color: #07672f;
      border: 1px solid #07672f;
      text-align: left;
      padding-left: 30px;
      font-family: 'Helvetica';

      &::placeholder,
      &::-webkit-input-placeholder {
        color: #c4c4c4;
        font-weight: 400;
        text-align: left;
        opacity: 0.5;
        font-family: 'Helvetica';
      }

      &:-webkit-autofill:focus {
        background-color: transparent;
      }

      padding: 20px;
      min-height: 120px;
      max-height: 400px;
    }
  }

  .prizes {
    &__preview,
    &__description,
    &__exchange,
    &__congrats {
      background-image: none;
    }

    &__exchange {
      b,
      span {
        font-weight: 400;
      }
    }
  }

  .profile,
  .profile__address,
  .profile__consumption,
  .profile__history,
  .profile__identification,
  .profile__information,
  .profile__phone,
  .profile__points__details,
  .profile__points {
    background-color: white;
    background-image: none;
  }

  .profile__consumption {
    &__empty {
      text-align: center;
    }
  }

  // -- End Route --
  .endroute {
    background-image: none;
    @include buttonGeneric();

    &__title {
      font-family: Balinese Culture;
      font-size: 48px;
      font-weight: 400;
    }
  }

  // -- Album --

  .album {
    // -- Multimedia --
    &__multimedia {
      // -- Upload --
      &__upload {
        background-color: #fbbc4d;
        background-image: none;

        .title {
        }

        .button-back {
        }

        .button-upload {
        }

        .rules-container {
          .rules-title {
          }

          .rules-content {
          }
        }

        .title__client {
          color: #000;
          text-align: center;
          font-family: Balinese Culture;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px;
        }

        .subtitle__client {
          color: #000;
          text-align: center;
          font-family: Frank;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .link__client {
          color: #000;
          text-align: center;
          font-family: Frank;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration-line: underline;
          text-transform: uppercase;
        }

        .text__client {
          color: #000;
          text-align: center;
          font-family: Frank;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .subtitle__confirm {
          color: #000;
          text-align: center;
          font-family: Balinese Culture;
          font-size: 21px;
          font-style: normal;
          font-weight: 400;
          line-height: 35px;
        }

        .input__client {
          width: 343px;
          height: 50px;
          border-radius: 25px;
          border: 1px solid black;
          color: #000;
          text-align: center;
          font-family: Frank;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .input__name {
          font-size: 16px;
        }

        &__popup {
          background-color: white;

          .container__pp {
            height: 100%;
          }

          h2 {
            color: black;
            text-align: center;
            /* ALL STAR H2 */
            font-family: Balinese Culture;
            font-size: 48px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
          }

          p {
            color: black;
            text-align: center;
            /* ALL STAR Párrafo */
            font-family: Frank;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        // -- Camera --
        .camera {
          .webcam {
          }

          .button-capture {
          }
        }

        // -- Already Image --
        .already-image {
          .card {
            .image {
            }
          }

          .button-generic {
          }

          .button-cancel {
          }
        }

        // -- How To Participate --
        &__how-to-participate {
          background-color: white;
          background-image: none;

          .container {
            .title {
            }

            .subtitle {
            }

            .content {
            }
          }
        }

        // -- Rules --
        &__rules {
          .container {
            .title {
            }

            .content {
            }
          }
        }

        // -- Validate Error --
        &__view-error {
          .title {
          }

          .subtitle {
          }
        }

        // -- Ready --
        &__ready {
          .icon {
          }

          .title {
          }

          .description {
          }

          .button-back {
          }
        }

        // -- Loader validate --
        .loader-validate {
          .title {
          }

          .subtitle {
          }
        }
      }
    }
  }

  // -- Album --
  .album {
    // -- Multimedia --
    &__multimedia {
      // -- Upload --
      &__upload {
        &__how-to-take-photo {
          background-color: #b54540;

          &__title {
            color: #fff;
            font-family: Balinese Culture;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            text-align: start;
            width: 300px;
          }

          &__subtitle {
            color: #fff;
            text-align: center;
            font-family: Balinese Culture;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 107.8%;
          }

          &__img {
            border-radius: 8px;
            border: 2px dashed var(--Blanco-Cristal, #fff);
            margin: 0 40px;
            width: 300px;
          }

          &__button {
            color: #fff;
            text-align: center;
            font-family: Frank;
            font-size: 10px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-decoration-line: underline;
            text-transform: uppercase;
          }

          li {
            color: #fff;
            font-family: Frank;
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            width: 300px;
          }

          &__text {
            color: #000;
            font-family: Frank;
            font-size: 8px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-decoration-line: underline;
            margin-top: 12px;
          }

          &__container {
          }
        }

        // -- Validate Error --
        &__view-error {
          background-color: white;

          .title {
            font-family: Balinese Culture;
            font-size: 48px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            color: var(--HNK-ALL-STAR-negro, #000);
          }

          .subtitle {
            color: var(--HNK-ALL-STAR-negro, #000);
            font-family: Frank;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          span {
            font-family: Frank;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: var(--HNK-ALL-STAR-negro, #000);
          }

          p {
            color: var(--HNK-ALL-STAR-rojo, #b54540);
            font-family: Frank;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            span {
              color: var(--HNK-ALL-STAR-rojo, #b54540);
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  .redeem {
    background-color: #b54540;
  }

  .terms-and-conditions {
    background-image: none;

    .container-terms {
      h1 {
        font-family: Balinese Culture;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 107.8%;
        color: var(--HNK-ALL-STAR-rojo, #b54540);
      }

      p,
      h2,
      li {
        margin-top: 12px;
        font-family: Frank;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: var(--HNK-ALL-STAR-negro, #000);
      }
    }
  }

  .privacy-politics {
    background-image: none;

    .container-priv {
      h1,
      h2 {
        font-family: Balinese Culture;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 107.8%;
        color: var(--HNK-ALL-STAR-rojo, #b54540);
      }

      p,
      li,
      h4,
      a,
      b {
        margin-top: 12px;
        font-family: Frank;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: var(--HNK-ALL-STAR-negro, #000);
      }
    }
  }

  .help {
    background-image: none;

    textarea {
      font-weight: 400;
    }
  }

  .Collapsible {
    span {
      color: black;
      font-family: Balinese Culture;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 107.8%;
      text-transform: uppercase;

      p,
      span {
        color: black;
        font-family: Frank;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &::after {
        color: #b54540;
      }
    }
  }

  .prizes {
    &__preview {
      background-color: white;
    }

    &__description {
      background-color: #b54540;
    }

    &__exchange {
      background-color: #b54540;
    }

    &__congrats {
      background-color: white;
    }
  }

  .slick-dots .slick-active div {
    opacity: 100 !important;
    background-color: black;
  }

  .help {
    background-color: white;
  }

  .privacy-politics,
  .terms-and-conditions {
    background-color: white;
  }

  .congrats {
    background-color: #b54540;
    background-image: none;
  }

  .footer {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    // gap: 15px;
    opacity: 0.6;

    &__text {
      color: #231f20;
      text-align: center;
      font-family: Arial;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.6px;
    }

    &__image {
      width: 56px;
      // height: 21px;
    }
  }

  .menuprofile {
    &__container {
      border-bottom: 1px solid #fbbc4d;
    }
  }

  .consumption-update {
    &__alert {
      background-color: white;
    }
  }

  .consumptionform {
    background-image: none;

    &__clientnum {
      color: black;
      text-align: center;
      font-family: Balinese Culture;
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 35px;
    }

    &__help {
      color: #000;
      text-align: center;
      font-family: Frank;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &__link {
      text-decoration-line: underline;
    }

    &__checkdata {
      &__title {
        text-align: center;
        font-family: Balinese Culture;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px;
        color: black;
      }

      &__clientNum {
        border-radius: 50px;
        border: 1px solid #000;

        p {
          color: #000;
          text-align: center;
          font-family: Frank;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    &__input {
      font-size: 24px;
      font-family: Frank;
      background-color: transparent;
      border: 1px solid black;
    }

    .title__client {
      color: #000;
      text-align: center;
      font-family: Balinese Culture;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
    }

    .subtitle__client {
      color: #000;
      text-align: center;
      font-family: Frank;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .link__client {
      color: #000;
      text-align: center;
      font-family: Frank;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
      text-transform: uppercase;
    }

    .text__client {
      color: #000;
      text-align: center;
      font-family: Frank;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .subtitle__confirm {
      color: #000;
      text-align: center;
      font-family: Balinese Culture;
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 35px;
    }

    .input__client {
      width: 343px;
      height: 50px;
      border-radius: 25px;
      border: 1px solid black;
      color: #000;
      text-align: center;
      font-family: Frank;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .input__name {
      font-size: 16px;
    }

    &__popup {
      background-color: white;

      .container__pp {
        height: 100%;
      }

      h2 {
        color: black;
        text-align: center;
        /* ALL STAR H2 */
        font-family: Balinese Culture;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
      }

      p {
        color: black;
        text-align: center;
        /* ALL STAR Párrafo */
        font-family: Frank;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .container__pp {
      background-color: white;
    }
  }

  .referrals {
    &__modal {
      background-color: white;
      background-image: none;

      &__show-rules {
        color: #000;
        text-align: center;
        font-family: Frank;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
        text-transform: uppercase;
      }

      &__title {
        padding-top: 16px;
        color: #000;
        text-align: center;
        font-family: 'Balinese Culture';
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;

        span {
          color: #b54540;
          text-align: center;
          font-family: 'Balinese Culture';
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 107.8%; /* 25.872px */
        }
      }
      &__content {
        p {
          span {
            color: #000;
            font-family: 'Balinese Culture';
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 107.8%; /* 19.404px */
          }

          color: rgba(0, 0, 0, 0.5);
          font-family: Frank;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        &__img {
        }
      }
    }

    &__rules {
      &__title {
        color: #000;
        text-align: center;

        /* ALL STAR H3 */
        font-family: 'Balinese Culture';
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 93.75% */
      }

      &__list {
        p {
          color: #000;
          leading-trim: both;
          text-edge: cap;
          font-family: Frank;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 17px 16px 16px 9px;

      &__title {
        display: flex;
        color: #000;
        leading-trim: both;
        text-edge: cap;
        font-family: 'Balinese Culture';
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 93.75% */

        h2 {
          padding-top: 4px;
        }

        &__arrow {
          height: 35px;
        }
      }

      &__download-btn {
        display: flex;
        padding: 2px 8px;
        align-items: center;
        gap: 4px;
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, 0.72);
        background: rgba(255, 255, 255, 0.16);
        color: rgba(0, 0, 0, 0.72);
        font-family: Frank;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        p {
          padding-top: 2px;
        }
      }
    }

    &__progress-bar {
      display: flex;
      width: calc(100% - 32px);
      padding: 8px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid #ccc;
      background: #fff;
      margin: 0 auto;
      margin-bottom: 16px;

      &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &__referrals {
          color: #000;
          leading-trim: both;
          text-edge: cap;
          font-family: Frank;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        &__points {
          color: #000;
          text-align: right;
          leading-trim: both;
          text-edge: cap;
          font-family: Frank;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }

      &__track {
        background: #ccc;
        height: 4px;
        width: 100%;

        &__fill {
          width: 0%;
          background-color: #b54540;
          height: 4px;
        }
      }
    }

    &__code {
      background-color: #b54540;
      display: flex;
      width: calc(100% - 32px);
      padding: 16px;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      flex-shrink: 0;
      margin: 0 auto;
      border-radius: 8px;
      transition: height 1.5s ease-in-out;

      &__title {
        color: #fff;
        font-family: 'Balinese Culture';
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%; /* 26.4px */
      }

      &__qr {
        background-color: white;
        padding: 10px;
        border-radius: 8px;
      }

      &__share {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        gap: 8px;

        &__text {
          color: #fff;
          text-align: center;
          font-family: Frank;
          font-size: 16px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
          text-transform: uppercase;
          margin: 0 auto;
        }
      }

      &__hex-digit {
        display: flex;
        height: 50px;
        padding: 11px 15.2px 11.5px 13.2px;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        background: #fff;
        margin: 0 auto;
        width: 100%;
        border-radius: 25px;
        background: #fff;

        p {
          color: #000;
          text-align: center;
          font-variant-numeric: lining-nums proportional-nums;
          font-family: Frank;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: uppercase;
        }
      }

      &__button {
        margin-bottom: 10px;

        &__copy {
          height: 40px !important;
        }

        &__share {
          background-color: #b54540;
          color: #fff;
          border: 1px solid black;
          height: 40px !important;
        }
      }

      &__dynamic {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0px;

        &__title {
          color: #fff;
          font-family: Frank;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        &__icon {
        }

        &__content {
          max-height: 0;
          opacity: 0;
          overflow: hidden;
          transition: max-height 0.5s ease, opacity 0.5s ease;
          padding: 0 10px; /* Evita que el contenido se muestre antes del tiempo */

          p {
            color: #fff;
            font-family: Frank;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          &__empty {
            color: #fff;
            font-family: Frank;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            height: 50px;
          }

          &__active {
            opacity: 1;
            padding: 10px;
          }

          &__list-item {
            display: flex;
            gap: 16px;
            padding-bottom: 8px;

            p {
              color: #fff;
              font-family: Frank;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          &__prize {
            &__title {
              color: #fff;
              leading-trim: both;
              text-edge: cap;
              font-family: Frank;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              padding: 8px 0px 8px 0px;
            }

            &__img {
              margin: 0 auto;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .referral-input {
    padding-right: 44px;
  }

  .referral-input-sign-up {
    margin-right: 8px;
  }

  .error {
    background-color: white;
    @include buttonGeneric();
    background-image: none;

    &__title {
      font-family: Balinese Culture;
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }

    &__description {
      font-family: Frank;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .prizes {
    .not-found {
      color: var(--HNK-ALL-STAR-negro, #000);
      text-align: center;
      font-family: Frank;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
